import React from "react";
import Header from "../components/header/header.js";
import CategoryButtons from "../components/categoryButtons/categoryButtons";
import Cable from "../assets/img/svg/cable.svg";
import Image from "../components/image/image.js";
import Pencil from "../assets/img/svg/plugIcon/pencil.svg";
import HeartBeat from "../assets/img/svg/plugIcon/heartbeat.svg";
import Bar from "../assets/img/svg/plugIcon/bar.svg";
import Floppy from "../assets/img/svg/plugIcon/floppy.svg";
import Merge from "../assets/img/svg/plugIcon/merge.svg";
import Wrench from "../assets/img/svg/plugIcon/wrench.svg";

const PlugAndPlay = () => {
  return (
    <>
      <Header
        title="Plug&amp;Play"
        description={`Il gateway in configurazione standard è Plug&Play e di facile installazione. `}
        categoryIcon={{ icon: Cable, alt: "Plug&Play Banner" }}
        metaDescription="Il gateway in configurazione standard è Plug&amp;Play e di facile installazione."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <h2>
              L'accesso al BackOffice è possibile con qualunque dispositivo
              possa collegarsi ad internet con un browser, (consigliamo Chrome),
              e con estrema facilità puoi avere il controllo della tua WiFi.
            </h2>
            <p className="description">
              <br />
              <br />
              Per configurazioni avanzate, attraverso i servizi cloud che
              mettiamo a disposizione per l’acquisizione ed il monitoraggio
              degli apparati, potrai gestire in maniera approfondita ogni
              singolo access point e/o switch configurato per la tua rete.
              <br />
              <br /> Per gli utenti specializzati, offriamo la possibilità di
              configurare tramite provisioning, il gateway per gestione di reti
              avanzate con funzionalità dedicate, permettendo di espandere la
              rete in totale autonomia.
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <div className="container">
        <Image filename="adminPanel.png" alt="a" />
      </div>
      <div className="section" />
      <div className="container">
        <div className="columns">
          <div className="column">
            <img src={Pencil} alt="" />
            <br />
            <span className="azure bold">Controllo totale</span>
            <br />
            <p>
              Modifica il nome della tua rete, personalizza il logo, cambia il
              testo e molto altro.
            </p>
          </div>
          <div className="column is-offset-2">
            <img src={HeartBeat} alt="" />
            <br />
            <span className="azure bold">Monitoraggio 24/7</span>
            <br />
            <p>
              Un controllo costante delle risorse in uso e di eventuali
              malfunzionamenti.
            </p>
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column">
            <img src={Bar} alt="" />
            <br />
            <span className="azure bold">Statistiche avanzate</span>
            <br />
            <p>Tieni sempre sotto controllo i dati della tua rete.</p>
          </div>
          <div className="column is-offset-2">
            <img src={Floppy} alt="" />
            <br />
            <span className="azure bold">Esportazione dati</span>
            <br />
            <p>
              Esporta senza problemi tutti i dati dei tuoi utenti che si sono
              collegati alla rete.
            </p>
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column">
            <img src={Merge} alt="" />
            <br />
            <span className="azure bold">Connessioni in tempo reale</span>
            <br />
            <p>
              Controlla e gestisci le connessioni alla tua rete in tempo reale.
            </p>
          </div>
          <div className="column is-offset-2">
            <img src={Wrench} alt="" />
            <br />
            <span className="azure bold">Supporto da remoto</span>
            <br />
            <p>
              Ogni rete è predisposta per l’intervento da remoto da parte dei
              nostri tecnici.
            </p>
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column">
            <span className="azure">Come può aiutarti Connect@You?</span>
          </div>
          <div className="column">
            <p className="description">
              Semplifica l’installazione e la gestione della tua rete, facilita
              l’assistenza per il tuo IT Manager!
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <p className="description">
              Il Pannello Admin di facile ed intuitiva gestione, ti permette di
              monitorare diverse informazioni utili alla tua struttura.
              <br />
              <br />
              Accedendo al Back Office con le tue credenziali potrai tenere
              sempre sotto controllo la tua rete, consultare le statistiche ed
              esportare i dati di tutti gli utenti che si sono collegati alla
              tua WiFi (CSV-JSON).
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <p className="center">Ti potrebbe interessare...</p>
        </div>
      </div>
      <div className="section" />
      <CategoryButtons titlePage="Plug&amp;Play" />
    </>
  );
};

export default PlugAndPlay;
